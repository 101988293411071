@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        background: linear-gradient(180deg, $tertiary-color 0%, rgba(255, 255, 255, 0.00) 57.01%);
        @include border-radius(25px 25px 0 0);

        .dialog-content {
            display: none;
        }

    }
}