@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        background: $base-black;
        color: $base-white;
        position: relative;

        .card-logo {
            border-radius: 20px;

            @media (max-width: $breakpoint-lg) {
                max-width: 150px;
            }
        }

        .js-panel-border {
            border: none !important;
        }

        .contentWrapper {
            padding: 10rem 0;
            min-height: 800px;
            z-index: 1;
        }

        .imageWrapper {
            right: 0;
            top: 0;
            bottom: 0;
            width: 60%;

            .card-img {
                width: 100%;
                height: 100%;
                @include object-fit;
            }
        }

        .spacer {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 99vw;
            height: 99vh;
            max-width: 99vw;
            max-height: 99vh;
        }

        .mainImageWrapper {
            z-index: 1;
            padding: 11rem 8rem;

            .card-img {
                width: 100%;
                height: 100%;
                aspect-ratio: 1 / 1;
                @include object-fit;
            }
        }

        .js-container {
            margin: 3rem 0;

            .js-form-input-wrapper {
                background: $base-black;
                @include flex;
                @include border-radius(50px);
                padding: 0.5rem 0.5rem 0.5rem 2rem;
                border: 1px solid $base-white;

                .js-input-wrapper-query {
                    padding-right: 1rem;
                    width: 100%;
                    @include flex;
                    @include align-items(center);

                    input {
                        outline: none;
                        width: 100%;
                        background: transparent;
                        color: $base-white;
                        border: none;
                    }
                }

                .js-button {
                    white-space: nowrap;
                    @include primaryBtn;
                    margin: 0;
                    border: none;

                    &:after {
                        content: none;
                    }
                }
            }
        }

        .banner-anchor {
            z-index: 1;
            margin-left: -0.5rem;
            bottom: 50px;
            background: $primary-color;
            padding: 0.6rem 0.875rem;
            cursor: pointer;
            @include trans;
            @include border-radius(50px);

            svg {
                width: 20px;
                height: 20px;

                path {
                    fill: $base-black;
                }
            }

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        @media (max-width: $breakpoint-lg) {
            .contentWrapper {
                padding: 12rem 0 0 0;
                min-height: auto;
            }

            .mainImageWrapper {
                padding: 0 0 10rem 0;
            }
        }
    }

    &:global.featured-employer-page {

        .imageWrapper {
            width: 100%;

            .card-img {
                object-position: 60%;
            }
        }

        &:before {
            content: none;
        }
    }

    &:global.featured-employer-page.hidden-image-1 {
        .contentWrapper {
            padding: 5rem 0;
        }

    }

    &:global.featured-employer-page {
        @media (max-width: $breakpoint-md) {
            .post-content {
                color: $base-white;
            }

            .imageWrapper {
                &:before {
                    content: "";
                    position: absolute;
                    background: rgba($base-black, 0.4);
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }

    &:global.anchor-offset {
        margin-top: 120px;
    }

    &:global.featured-employer-page.invert {
        text-align: right;
        color: $font-color;

        .imageWrapper {
            width: 100%;

            .card-img {
                object-position: 30%;
            }
        }

        .row {
            @include flex-direction(row-reverse);
        }
    }

    &:global:before {
        content: "";
        background: linear-gradient(90deg, #000 67.66%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        width: 60%;
    }

    &:global.hidden-image-1 {
        .contentWrapper {
            padding: 10rem 0 5rem 0;
            min-height: 500px;
        }
    }

    &:global.page {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: calc(50% + 3rem);
            background-color: rgba(#ffffff, 0.75);
        }
    }

    &:global.santander-page {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: calc(50% + 3rem);
            background-color: rgba(#ffffff, 0.75);
        }

        h2 {
            font-weight: 600;
            color: #ec0000;
        }
    }
}